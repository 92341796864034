import { CUR_YEAR } from '@/modules/budget-request/components/js/budgetCurYear';
import {IVariant, IVariantBase} from '@/modules/budget/budgetVariantService';

export function genListOfYears() {
    const res = [];
    for (let y = CUR_YEAR + 3; y > 2020; y--) {
        const txt = y.toString() + '-' + (y + 2).toString();
        const year = {
            value: y,
            text: txt
        };
        res.push(year);
    }
    return res;
}

export function listForCorrect(listVariants: any[], repYear: number) {
    let res = [];
    if (listVariants.length>0) {
        res = listVariants.filter(v => (v.year === repYear && v.data_type === 4));
    }
    for (const v of res) {
        if (v.data_type != 4) {
            v.disabled = true;
        }
    }
    return res;
}

export function listForClarify(listVariants: any[], repYear: number) {
    let res: IVariantBase[] = [];
    if (listVariants.length>0) {
        res = listVariants.filter(v => (v.year === repYear && v.data_type === 3));
    }
    for (const v of res) {
        if (v.data_type != 3) {
            v.disabled = true;
        }
    }
    return res;
}

export function variantName(variant: IVariant, fieldName?: string) {
    let res = variant.name_ru;
    if (fieldName) {
        const tmp: any = Object.assign({}, variant);
        if (tmp[fieldName]) { res = tmp[fieldName]; }
    }
    if (variant.status === true) {
        res = res + ' (утв)';
    }
    if (variant.attribute === true) {
        res = res + ' (акт)';
    }
    return res;
}

export function variantNameLangSupport(variant: IVariant, langCode = 'ru') {
    let res = variant.name_ru;
    const fieldName = `name_${langCode}`;
    const tmp: any = Object.assign({}, variant);
    if (tmp[fieldName]) { res = tmp[fieldName]; }

    if (variant.status === true) {
        switch (langCode) {
            case 'kk':
                res = res + ' (бекiт)';
                break;
            case 'en':
                res = res + ' (appr)';
                break;
            default:
                res = res + ' (утв)';
                break;
        }
    }
    if (variant.attribute === true) {
        switch (langCode) {
            case 'kk':
                res = res + ' (өзек)';
                break;
            case 'en':
                res = res + ' (act)';
                break;
            default:
                res = res + ' (акт)';
                break;
        }
    }
    return res;
}

export function variantForLimitIncome(listVariants: any[], year: number, region: string, dataType?: number) {
    return JSON.parse(JSON.stringify(
        listVariants
        .filter(v =>
            v.year === year
            && (v.is_deleted === null || v.is_deleted === false)
            && v.region_code === region
            && (dataType ? v.data_type === dataType : (v.data_type === 1 || v.data_type === 2 || v.data_type === 3 || v.data_type === 4))
            )
        .sort((a, b) => a.date_time < b.date_time ? 1 : -1)
    ));
}